import type {History} from 'history'
import {isNumber} from 'lodash'

export const setSearchParam = (search: string, key: string, value?: string): string => {
  const urlParams = new URLSearchParams(search)
  if (value) {
    urlParams.set(key, value)
  } else {
    urlParams.delete(key)
  }
  return urlParams.toString()
}

export const setSearchParamToHistory = (history: History, key: string, value?: string) => {
  history.push({search: setSearchParam(history.location.search, key, value)})
}

export const parameterToInt = (str?: string): number | undefined => {
  if (!str) {
    return undefined
  }
  const parsed = parseInt(str, 10)
  return isNaN(parsed) ? undefined : parsed
}

export const intToParameter = (num: number | undefined | null): string | undefined =>
  isNumber(num) ? String(num) : undefined
